
<div class="container haveData">
  <h2 style="margin: 20px 0px;">{{util.translate('My Bookings')}}</h2>
  <div class="card_div" *ngFor="let item of myOrders" >

    <div class="resto_detail">
      <div class="back_image"
        [ngStyle]="{'background-image':'url('+api.mediaURL+item.cover+'),url(assets/placeholder.jpg)'}"></div>
      <div style="margin-left: 20px;display: flex;flex-direction: column;">
        <label class="res_name" style="margin: 0px;">{{item.name}}</label>
        <label class="res_location" style="margin: 0px;">
          {{item.address}}
        </label>
      </div>
    </div>

    <div class="line_div"></div>
    <div class="order_detail">
      <label class="head_gray">{{util.translate('Table Reservation')}} For {{item.rev_type}}</label>
      
      <label class="head_gray">{{util.translate('Date ')}} : {{item.rev_date|date:'dd-MM-yyyy'}}</label> 
      <label class="">{{util.translate('Time')}} {{item.rev_time}}</label> 
    </div>

     

    <div class="status_detail" *ngIf="item.status =='completed' || item.status == 'delivered'">
      <div class="deliver_div">
        {{util.translate('Delivered')}}
      </div>
    </div>
    <div class="status_detail" *ngIf="item.status =='rejected' || item.status == 'cancel'">
      <div class="deliver_div">
        {{util.translate('Your Order is')}} {{item.status}}
      </div>
    </div>
    <div class="status_detail" *ngIf="item.status =='created' || item.status =='ongoing' || item.status === 'accepted'">
      <div class="deliver_div">
        {{util.translate('Your Order is')}} {{item.status}}
      </div>
      <div class="repeat_div" style="color: green;">
        <mdb-icon fas icon="location-arrow" color="primary"></mdb-icon> {{util.translate('Track Order')}}
      </div>
    </div>
  </div>


  <div *ngFor="let item of dummy" class="card_div">
    <div class="resto_detail">
      <div class="back_image">
        <ngx-skeleton-loader appearance="circle" [theme]="{ 'border-radius': '5px', height: '50px',width:'100%'}">
        </ngx-skeleton-loader>
      </div>
      <div style="margin-left: 20px;display: flex;flex-direction: column;">
        <label class="res_name" style="margin: 0px;">
          <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'120px'}">
          </ngx-skeleton-loader>
        </label>
        <label class="res_location" style="margin: 0px;">
          <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
        </label>
      </div>
    </div>
  </div>
</div>
